/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/auth';

import { ref, computed } from '@vue/composition-api';
import axios from 'axios';

type Customer = {
  credit_available: Number;
}

const user = ref<firebase.User>(null);
const customer = ref<Customer>(null);
const isAuthed = computed<boolean>(() => user.value !== null);
const uid = computed<string>(() => user.value?.uid);

function getUserToken() {
  return firebase.auth().currentUser.getIdToken(true);
}

function login() {
  const provider = new firebase.auth.GoogleAuthProvider();
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(async cred => {
      // await axios.post<Customer>('/api/customer/', {
      //   headers: {
      //     Authorization: await getUserToken(),
      //   },
      // });
      user.value = cred.user;
      return cred;
    });
}

function logout() {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      user.value = null;
      customer.value = null;
    });
}

async function fetchCustomer() {
  const cus = await axios.get<Customer>('/api/customer/me/', {
    headers: {
      Authorization: await getUserToken(),
    },
  });
  customer.value = cus.data;
  return customer.value;
}

export default () => ({
  user,
  customer,
  isAuthed,
  uid,

  login,
  logout,
  getUserToken,
  fetchCustomer,
});
