




















import Vue from 'vue';

export default Vue.extend({
  name: 'Alert',
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    alertMessages() {
      return this.$store.getters['app/alertMessages'];
    },
    showAlert() {
      return this.alertMessages.length > 0;
    },
  },
  watch: {
    alertMessages(val) {
      if (val.length > 0) {
        this.clearTimer();
        this.timer = setTimeout(() => {
          this.shiftAlert();
        }, 5000);
      } else {
        this.clearTimer();
      }
    },
  },
  methods: {
    shiftAlert() {
      this.$store.dispatch('app/shiftAlert');
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
  },
});
