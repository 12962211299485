import firebase from 'firebase/app';
import 'firebase/auth';
import useAuth from '../composables/use-auth';

const { user, fetchCustomer } = useAuth();

const config = {
  apiKey: 'AIzaSyD5LmY-1SAPRA_m6TK99Auk1jjyv8uWYzo',
  authDomain: 'creditok-datahub.firebaseapp.com',
  projectId: 'creditok-datahub',
};
firebase.initializeApp(config);

window.dfLoadingList.add('firebase-signin');
firebase.auth().onAuthStateChanged(async authUser => {
  user.value = authUser;
  if (authUser) {
    await fetchCustomer();
  }
  window.dfLoadingList.remove('firebase-signin');
});
