import Vue from 'vue';
import {
  GetterTree, MutationTree, ActionTree,
} from 'vuex';
import { make } from 'vuex-pathify';

class ApplicationState {
    alertMessages: { message: string, type: string }[] | string[] = []
}

const state: ApplicationState = new ApplicationState();

const getters: GetterTree<ApplicationState, {}> = {
  ...make.getters(state),
};

const mutations: MutationTree<ApplicationState> = {
  ...make.mutations(state),
  ADD_ALERT(state, payload) {
    if (state.alertMessages.indexOf(payload) < 0) {
      state.alertMessages.push(payload);
    }
  },
  SHIFT_ALERT(state) {
    state.alertMessages.shift();
  },
};

const actions: ActionTree<ApplicationState, {}> = {
  ...make.actions(state),
  updateElement({ commit }, data): void {
    commit('UPDATE_ELEMENT', data);
  },
  shiftAlert({ commit }): void {
    commit('SHIFT_ALERT');
  },
  addAlert({ commit }, data): void {
    commit('ADD_ALERT', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
