import useLocale from '../composables/use-locale';

const { locale } = useLocale();

export default router => {
  router.beforeEach(async (to, from, next) => {
    /* Skip all redirect logic if not found */
    if (to.name === 'notFound') {
      return next();
    }

    /* If lang not found, Set to default */
    const { lang: languageTo } = to.params;

    if (languageTo) {
      return next();
    }
    return next({
      ...to,
      params: {
        ...to.params,
        lang: locale.value,
      },
    });
  });
};
