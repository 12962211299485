/* tslint:disable */
import { VueConstructor } from 'vue';
import VueI18n from 'vue-i18n';
import locale from './assets/locale.json';

type PluginOptions = {
  i18n?: VueI18n,
}

function install(Vue: VueConstructor, options: PluginOptions = {}) {
  const { i18n } = options;

  const ComponentContext = require.context('./components/', true, /\.vue$/i, 'lazy');

  ComponentContext.keys().forEach((componentFilePath: string) => {
    const componentName = componentFilePath.split('/').pop().split('.')[0];
    Vue.component(`DynamicReport${componentName}`, () => ComponentContext(componentFilePath));
  });

  const ModuleContext = require.context('./modules/', true, /\.vue$/i, 'lazy');

  ModuleContext.keys().forEach((ModuleFilePath: string) => {
    const ModuleName = ModuleFilePath.split('/').pop().split('.')[0];
    Vue.component(`DynamicReport${ModuleName}`, () => ModuleContext(ModuleFilePath));
  });

  if (i18n) {
    console.log('Merged dynamic-report i18n');
    ['en', 'th'].forEach(lang => {
      const originalMsg = JSON.parse(JSON.stringify(i18n.getLocaleMessage(lang)));
      i18n.setLocaleMessage(lang, locale[lang]);
      i18n.mergeLocaleMessage(lang, originalMsg);
    });
  } else {
    console.log('Using local dynamic-report i18n');
    const locali18n = new VueI18n({
      locale: 'th',
      messages: locale,
      silentTranslationWarn: true,
    });
    Vue.mixin({ i18n: locali18n });
  }
}

export default {
  install,
};
