







import { defineComponent, ref, reactive } from '@vue/composition-api';
import Alert from './components/Alert.vue';

export default defineComponent({
  name: 'App',
  components: {
    Alert,
  },
  setup() {
    (window as any).dfLoadingList.remove('app');

    return {
    };
  },
});
