/* eslint-disable no-param-reassign */
import Router from 'vue-router';
import useAuth from '../composables/use-auth';

const { user, isAuthed } = useAuth();

export default (router:Router) => {
  router.beforeEach(async (to, from, next) => {
  /* Skip all redirect logic if not found */
    if (to.name === 'notFound') {
      return next();
    }

    // Check Auth Routes
    if (to.meta?.auth || to.matched.some(record => record.meta.auth)) {
      if (!isAuthed.value) {
        return next({
          name: 'login',
          params: { nextUrl: to.fullPath },
        });
      }
    }

    return next();
  });
};
