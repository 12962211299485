import Vue from 'vue';
import Buefy from 'buefy';

import './plugins/composition-api';
import './plugins/firebase';
import './plugins/dynamic-report';
import i18n from './plugins/i18n';

import store from './store';
import router from './router';
import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(Buefy);

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
