// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://themes.creditok.co/themes/default/style.css);"]);
exports.push([module.id, "@import url(https://cdn.creditok.co/fonts/thaisans-neue/thaisans-neue.css);"]);
exports.push([module.id, "@import url(https://cdn.creditok.co/fonts/noto-sans/index.css);"]);
// Module
exports.push([module.id, "@-webkit-keyframes spinAround{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}@keyframes spinAround{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}body,button,input,select,textarea{font-family:Noto Sans Thai,Noto Sans,thaisans-neue,Times New Roman,Arial,Verdana,Helvetica}.call-to-action-header{font-size:1.7rem;font-weight:700}.empty-state-image{max-width:240px;max-height:240px;width:100%;height:100%}.tabs ul{border-bottom:3px solid transparent;width:100%;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap}.tabs .tab{font-weight:700}.tabs .tab a{color:#707070}.tabs .tab.is-active a,.tabs .tab a{-webkit-transition:all .2s;transition:all .2s;-webkit-box-sizing:border-box;box-sizing:border-box;border-bottom-width:3px}.tabs .tab.is-active a{color:#04bebe;font-size:1rem}.help.is-danger{height:1rem}@media screen and (max-width:768px){.tabs ul{width:100%;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap}.tabs li{width:50%}}", ""]);
// Exports
module.exports = exports;
