import get from 'lodash/get';
import camelCase from 'lodash/camelCase';

const originalTitle = document.title;

export default router => {
  router.beforeEach(async (to, from, next) => {
    const upperCaseRouteName = camelCase(to.name);
    document.body.id = camelCase(`route ${upperCaseRouteName}`);

    let title = get(to, 'meta.title', originalTitle);
    title = get(to, 'meta.title_prefix', '') + title + get(to, 'meta.title_postfix', '');
    document.title = title;

    return next();
  });
};
