import Vue from 'vue';
import Vuex from 'vuex';
import pathify, { make } from 'vuex-pathify';
import app from './app';

Vue.use(Vuex);

export class RootState {
}

export default new Vuex.Store({
  modules: {
    app,
  },
  plugins: [
    pathify.plugin,
  ],
});
