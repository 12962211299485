import Vue from 'vue';
import Router from 'vue-router';
import PageNotFound from '../views/PageNotFound.vue';
import routerLangChecker from './router-lang-checker';
import routerAuthChecker from './router-auth-checker';
import routerDocumentChanger from './router-document-changer';

function onRouteLoaded(component) {
  window.dfLoadingList.remove('router'); return component;
}

function wrapPromiseWithLoader(promise: Promise<any>) {
  return new Promise(resolve => {
    window.dfLoadingList.add('router');
    resolve(promise);
  })
    .then(onRouteLoaded);
}

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/:lang([A-Za-z]{2})?',
      component: () => wrapPromiseWithLoader(import(/* webpackChunkName: "routerComponents" */ '../layouts/LayoutDefault.vue')),
      children: [
        {
          path: '',
          name: 'home',
          redirect: to => ({
            name: 'businessinfo',
            params: to.params,
          }),
        },
        {
          path: 'login',
          name: 'login',
          component: () => wrapPromiseWithLoader(import(/* webpackChunkName: "routerComponents" */ '../views/PageLogin.vue')),
        },
        {
          path: 'hub',
          component: () => wrapPromiseWithLoader(import(/* webpackChunkName: "routerComponents" */ '../layouts/LayoutHub.vue')),
          redirect: to => ({
            name: 'businessinfo',
            params: to.params,
          }),
          children: [
            {
              path: 'dbd',
              name: 'dbd',
              component: () => wrapPromiseWithLoader(import(/* webpackChunkName: "routerComponents" */ '../views/PageDBD.vue')),
            },
            {
              path: 'coj',
              name: 'coj',
              component: () => wrapPromiseWithLoader(import(/* webpackChunkName: "routerComponents" */ '../views/PageCOJ.vue')),
            },
            {
              path: 'businessinfo',
              name: 'businessinfo',
              component: () => wrapPromiseWithLoader(import(/* webpackChunkName: "routerComponents" */ '../views/PageBusinessInfo.vue')),
            },
            {
              path: 'gov',
              name: 'gov',
              component: () => wrapPromiseWithLoader(import(/* webpackChunkName: "routerComponents" */ '../views/PageGovernmentProject.vue')),
            },
            {
              path: 'tax_return',
              name: 'tax_return',
              component: () => wrapPromiseWithLoader(import(/* webpackChunkName: "routerComponents" */ '../views/PageTaxReturn.vue')),
            },
          ],
          meta: {
            auth: true,
          },
        },
      ],
    },
    {
      path: '/:lang([A-Za-z]{2})?*',
      name: 'notFound',
      component: PageNotFound,
      props: true,
    },
  ],
});

routerLangChecker(router);
routerAuthChecker(router);
routerDocumentChanger(router);

export default router;
